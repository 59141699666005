// src/components/PayPalButton.js
import React, { useEffect } from 'react';

const PayPalButtonComponent = ({ amount, onSuccess, onError, onCancel }) => {
  useEffect(() => {
    window.paypal.Buttons({
      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount,
            },
          }],
        });
      },
      onApprove: function (data, actions) {
        console.log('Order details:', data);
        return actions.order.capture().then(function (details) {
          console.log('Capture completed:', details);
          onSuccess(details);
        }).catch(function (err) {
          console.error('Capture failed:', err);
          onError(err);
        });
      },
      onError: function (err) {
        console.error('Transaction failed:', err);
        onError(err);
      },
      onCancel: function (data) {
        console.log('Transaction cancelled:', data);
        onCancel(data);
      },
      options: {
        clientId: 'ATQ-NN_2I-DjemqoFJFDsPCFNyQImBN0ZEKqDiSXvVTrf2oXSaDiFVGwH17f6roWRztOdg58FWP2F1kQ', // Replace with your sandbox Client ID
        currency: 'USD',
        vault: true,
      },
    }).render('#paypal-button-container');
  }, [amount, onSuccess, onError, onCancel]);

  return (
    <div id="paypal-button-container"></div>
  );
};

export default PayPalButtonComponent;
