// src/pages/index.js
import React from 'react';
import PayPalButtonComponent from './components/PayPalButton';
import Layout from "../../components/details-layout"

const IndexPage = () => {
  const handleSuccess = (details) => {
    console.log('Transaction completed by ' + details.payer.name.given_name);
    // Additional actions after successful payment
  };

  const handleError = (err) => {
    console.error('Transaction failed:', err);
    // Additional actions on payment failure
  };

  const handleCancel = (data) => {
    console.log('Transaction cancelled:', data);
    // Additional actions on payment cancellation
  };

  return (
    <div>
      <h1>My Gatsby PayPal Integration</h1>
      <PayPalButtonComponent
        amount="1.00"  // Set the payment amount
        onSuccess={handleSuccess}
        onError={handleError}
        onCancel={handleCancel}
      />

</div>
  );
};

export default IndexPage;
