import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./../css/styles.scss";
import "./../css/footer.scss";

import { Container, Row, Col, ul } from "react-bootstrap" 
import  FaFacebookF  from "../images/faFacebookF";
import  FaInstagram  from "../images/FaInstagram";
import  FaTwitter  from "../images/FaTwitter";

     

function Footer() {
	return (
    <div className="footer">
        <div className="footer-wrapper">
                <Row>
                <div class="col-sm-4">
                <Container>
                    <h2>Contact Us</h2>
                  <div id="contact-us">
                    <div class="elfsight-app-6fcbc23e-94fc-4b52-ac42-c19d0cfb5183"></div>
                    </div>
                </Container>
                </div>
                <div class="col-sm-4">
                    <div className="contact">
                        <Container>
                            <h2>Get in Touch</h2>
                            <ul className="social">
                                <li>
                                    <a href="https://www.facebook.com/people/Belize-Inland-Tours/100066646030826/" target="_blank"><FaFacebookF /></a>
                                    <a href=""><FaInstagram /></a>
                                    <a href=""><FaTwitter /></a>
                                </li>
                            
                            </ul>
                            <div className="address">
                                <p>
                                    <span>Tel: <a href="tel:5016156239">+(501) 615-6239</a></span><br/>
                                    <span>Office Hours: Mon - Sun, 8:00am to 4:00pm CST</span><br/>
                                </p>
                            </div>
                        </Container>
                    </div>
                </div>
                <div class="col-sm-4 last">
              
              
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121965.42546235163!2d-88.82079696718347!3d17.198424762947894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5dd6f9e9da387b%3A0x81d4bf72634fcd32!2sBelize%20Inland%20Tours%20%26%20Cave%20Tubing!5e0!3m2!1sen!2sbz!4v1700795741295!5m2!1sen!2sbz" width="100%" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </Row>
        </div>
        <div className="footer-copyright">
            <p>Copyright © {new Date().getFullYear()} Belize Inland Tours. All Rights Reserved. Belize. <a href="https://belbytedesigns.com/" target="_blank"> [ Powered by Belbyte Designs ]</a></p>
        </div>
	</div>
    

    );
}

export default Footer;